import * as productActions from "./productAction";

export const productFeatureKey = "productInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  products: [],
  count: 0,
  productDetails: {},
  category: [],
  industries: [],
  currentPage: 1,
  isProductUpdate:false
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // product list

    case productActions.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload.data,
        count: payload.count[0]?.total,
      };
    case productActions.PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    //set page no.
    case productActions.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };

    // product category

    case productActions.PRODUCT_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.PRODUCT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        category: payload.data,
      };

    case productActions.PRODUCT_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create category
    case productActions.CREATE_PRODUCT_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.CREATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case productActions.CREATE_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //   product create
    case productActions.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: payload.data,
      };

    case productActions.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //  get product
    case productActions.GET_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productActions.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetails: payload.data,
      };

    case productActions.GET_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // deleteProduct

    case productActions.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        isProductUpdate:false
      };
    case productActions.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isProductUpdate:true
      };
    case productActions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
