import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_URL;

export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAILURE = "PAYMENT_LIST_FAILURE";

export const PAYMENT_DETAILS_REQUEST = "PAYMENT_DETAILS_REQUEST";
export const PAYMENT_DETAILS_SUCCESS = "PAYMENT_DETAILS_SUCCESS";
export const PAYMENT_DETAILS_FAILURE = "PAYMENT_DETAILS_FAILURE";

export const paymentList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_LIST_REQUEST });
      const url = `${BASE_URL}/payment/admin/get/all/cards/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: PAYMENT_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: PAYMENT_LIST_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};
export const paymentDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PAYMENT_DETAILS_REQUEST });
      const url = `${BASE_URL}/payment/admin/get/single/card/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: PAYMENT_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: PAYMENT_DETAILS_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};
