import { combineReducers } from "redux";
import * as productReducer from "./product/productReducer";
import * as industryReducer from "./industries/industryReducer";
import * as contactReducer from "./contact/contactReducer";
import * as userReducer from "./user/userReducer";
import * as orderReducer from "./order/orderReducer";
import * as galleryReducer from "./gallery/galleryReducer";
import * as adminLoginReducer from "./adminLogin/adminLoginReducer";
import * as paymentReducer from "./payment/paymentReducer";
import * as rfqReducer from "./rfq/rfqReducer";
import * as inquiryReducer from "./inquiry/inquiryReducer";
import * as bannerReducer from "./banner/bannerReducer";
import * as bankReducer from "./bank/bank.reducers";
import * as paymentDetailReducer from "./paymentDetails/payment.reducers";
import * as qrReducer from "./qr/qrReducer";

export const rootReducer = combineReducers({
  [productReducer.productFeatureKey]: productReducer.reducer,
  [industryReducer.industryFeatureKey]: industryReducer.reducer,
  [contactReducer.contactFeatureKey]: contactReducer.reducer,
  [userReducer.userFeatureKey]: userReducer.reducer,
  [orderReducer.orderFeatureKey]: orderReducer.reducer,
  [galleryReducer.galleryFeatureKey]: galleryReducer.reducer,
  [adminLoginReducer.adminFeatureKey]: adminLoginReducer.reducer,
  [paymentReducer.paymentFeatureKey]: paymentReducer.reducer,
  [rfqReducer.rfqFeatureKey]: rfqReducer.reducer,
  [inquiryReducer.inquiryFeatureKey]: inquiryReducer.reducer,
  [bannerReducer.bannerFeatureKey]: bannerReducer.reducer,
  [bankReducer.bankFeatureKey]: bankReducer.reducer,
  [paymentDetailReducer.paymentDetailsFeatureKey]: paymentDetailReducer.reducer,
  [qrReducer.qrFeatureKey]: qrReducer.reducer,
});
