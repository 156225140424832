import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

export const GET_INQUIRY_LIST_REQUEST = "GET_INQUIRY_LIST_REQUEST";
export const GET_INQUIRY_LIST_SUCCESS = "GET_INQUIRY_LIST_SUCCESS";
export const GET_INQUIRY_LIST_FAILURE = "GET_INQUIRY_LIST_FAILURE";


export const GET_INQUIRY_DETAILS_REQUEST = "GET_INQUIRY_DETAILS_REQUEST";
export const GET_INQUIRY_DETAILS_SUCCESS = "GET_INQUIRY_DETAILS_SUCCESS";
export const GET_INQUIRY_DETAILS_FAILURE = "GET_INQUIRY_DETAILS_FAILURE";

export const getInquiryList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INQUIRY_LIST_REQUEST });
      const URL = `${BASE_URL}/inquiry/admin/get/inquiry/list/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_INQUIRY_LIST_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_INQUIRY_LIST_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};


export const getInquiryDetails = (inquiryId) => {
    return async (dispatch) => {
      try {
        dispatch({ type: GET_INQUIRY_DETAILS_REQUEST });
        const URL = `${BASE_URL}/inquiry/admin/get/inquiry/details/${inquiryId}/v1`;
        const res = await axios.get(URL, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        dispatch({ type: GET_INQUIRY_DETAILS_SUCCESS, payload: res?.data });
      } catch (error) {
        dispatch({ type: GET_INQUIRY_DETAILS_FAILURE, payload: error.message });
        console.log(error);
      }
    };
  };