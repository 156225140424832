import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { BiShow, BiHide } from "react-icons/bi";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as adminLoginAction from "../../redux/adminLogin/adminLoginAction";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleTogglePassword = (type) => {
    switch (type) {
      case "oldPassword":
        setShowOldPassword(!showOldPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      const data = { oldPassword, newPassword, confirmPassword };
      dispatch(adminLoginAction.changePassword(data, clearData));
    } catch (error) {
      console.log(error);
    }
  };

  const clearData = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  return (
    <>
      <section className="change_password">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="d-flex align-items-center justify-content-center"
          style={{ width: "70vh", margin:"auto" }}
        >
          <div className="auth-form-light text-left py-5 px-4 px-sm-5">
            <div className="title">
              Your new password must be different from old.
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showOldPassword ? "text" : "password"}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter Password"
                  required
                />
                <span
                  className="input-group-text password-toggle-icon"
                  onClick={() => handleTogglePassword("oldPassword")}
                >
                  {showOldPassword ? <BiHide /> : <BiShow />}
                </span>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Enter Password"
                  required
                />
                <span
                  className="input-group-text password-toggle-icon"
                  onClick={() => handleTogglePassword("newPassword")}
                >
                  {showNewPassword ? <BiHide /> : <BiShow />}
                </span>
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <div className="input-group">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Enter Password"
                  required
                />
                <span
                  className="input-group-text password-toggle-icon"
                  onClick={() => handleTogglePassword("confirmPassword")}
                >
                  {showOldPassword ? <BiHide /> : <BiShow />}
                </span>
              </div>
            </Form.Group>
            <div className="col-12">
              <div className="input_wrapper">
                <label className="password_instruction_label" htmlFor="">
                  *Password should be strong 8 characters or more, at least
                  contain one special character, one capital letter, one small
                  letter, and one number
                </label>
              </div>
            </div>
            <button
              className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
