import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_URL;

// const TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTJmNWE4N2E2M2ZiNTAyNWU1NTFjZGYiLCJpYXQiOjE2OTc2MDIyNzMsImV4cCI6MTcyOTEzODI3M30.WsnnzkxOuAQWln3BlN-M5p99qzgSWv2sMQBx5Js8ExI";

//  get contact list
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";
//  create contact
export const CREATE_CONTACT_REQUEST = "CREATE_CONTACT_REQUEST";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILURE = "CREATE_CONTACT_FAILURE";
//  get sungle contact
export const GET_SINGLE_CONTACT_REQUEST = "GET_SINGLE_CONTACT_REQUEST";
export const GET_SINGLE_CONTACT_SUCCESS = "GET_SINGLE_CONTACT_SUCCESS";
export const GET_SINGLE_CONTACT_FAILURE = "GET_SINGLE_CONTACT_FAILURE";

// export const createContactUs = () => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: CREATE_CONTACT_REQUEST });
//       const URL = `${BASE_URL}`;
//       const res = await axios.get(URL);
//       dispatch({ type: CREATE_CONTACT_SUCCESS, payload: res?.data });
//     } catch (error) {
//       dispatch({ type: CREATE_CONTACT_FAILURE, payload: error.message });
//       console.log(error);
//     }
//   };
// };

export const getContactUsList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONTACT_REQUEST });
      const URL = `${BASE_URL}/contact/us/list/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_CONTACT_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_CONTACT_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const getSingleContactUs = (contactId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_CONTACT_REQUEST });
      const URL = `${BASE_URL}/contact/us/${contactId}`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_SINGLE_CONTACT_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_SINGLE_CONTACT_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};
