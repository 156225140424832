import * as bankActions from "./bank.actions";

export const bankFeatureKey = 'bankInfo';

const initialState = {
    loading: false,
    errorMsg: "",
    details: {},
    isStatusChange: false
};

export const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case bankActions.UPDATE_BANK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bankActions.UPDATE_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                isStatusChange: true
            }

        case bankActions.UPDATE_BANK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }


        case bankActions.GET_BANK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case bankActions.GET_BANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case bankActions.GET_BANK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};