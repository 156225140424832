import * as paymentAction from "./paymentAction";

export const paymentFeatureKey = "paymentInfo";

const initialState = {
  loading: false,
  errorMsg: "",
//   count: 0,
  paymentList: [],
  paymentDetails: {},
  currentPage: 1,
  isPaymentUpdate:false
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case paymentAction.PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case paymentAction.PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentList: payload.data,
        // count: payload.count[0]?.total,
      };
    case paymentAction.PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case paymentAction.PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case paymentAction.PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentDetails: payload.data,
      };
    case paymentAction.PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    
    default:
      return state;
  }
};
