import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

export const PAYMENT_LIST_REQUEST = "PAYMENT_LIST_REQUEST";
export const PAYMENT_LIST_SUCCESS = "PAYMENT_LIST_SUCCESS";
export const PAYMENT_LIST_FAILURE = "PAYMENT_LIST_FAILURE";

export const PAYMENT_DETAILS_REQUEST = "PAYMENT_DETAILS_REQUEST";
export const PAYMENT_DETAILS_SUCCESS = "PAYMENT_DETAILS_SUCCESS";
export const PAYMENT_DETAILS_FAILURE = "PAYMENT_DETAILS_FAILURE";

export const PAYMENT_ACCEPT_REQUEST = "PAYMENT_ACCEPT_REQUEST";
export const PAYMENT_ACCEPT_SUCCESS = "PAYMENT_ACCEPT_SUCCESS";
export const PAYMENT_ACCEPT_FAILURE = "PAYMENT_ACCEPT_FAILURE";

export const PAYMENT_REJECT_REQUEST = "PAYMENT_REJECT_REQUEST";
export const PAYMENT_REJECT_SUCCESS = "PAYMENT_REJECT_SUCCESS";
export const PAYMENT_REJECT_FAILURE = "PAYMENT_REJECT_FAILURE";

export const getPaymentList = (skip) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PAYMENT_LIST_REQUEST });
            const url = `${BASE_URL}/order/payment/list/v1?skip=${skip}`;

            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: PAYMENT_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: PAYMENT_LIST_FAILURE, payload: error.message });
            toast.error(error?.response?.data?.message);
        }
    };
};

export const getPaymentDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PAYMENT_DETAILS_REQUEST });
            const url = `${BASE_URL}/order/payment/details/${id}/v1`;

            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: PAYMENT_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: PAYMENT_DETAILS_FAILURE, payload: error.message });
            toast.error(error?.response?.data?.message);
        }
    };
};

export const paymentAccept = (id, history) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PAYMENT_ACCEPT_REQUEST });
            const url = `${BASE_URL}/order/accept/payment/${id}/v1`;

            const response = await axios.put(url, {}, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: PAYMENT_ACCEPT_SUCCESS, payload: response.data });
            toast.success("Payment Accepted Successfully");
            history.goBack();
        } catch (error) {
            console.error(error);
            dispatch({ type: PAYMENT_ACCEPT_FAILURE, payload: error.message });
            // toast.error(error?.response?.data?.message);
        }
    };
};

export const paymentReject = (id, description, history) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PAYMENT_ACCEPT_REQUEST });
            const url = `${BASE_URL}/order/reject/payment/${id}/v1`;

            const response = await axios.put(url, { description }, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: PAYMENT_ACCEPT_SUCCESS, payload: response.data });
            toast.success("Payment Rejected");
            history.goBack();
        } catch (error) {
            console.error(error);
            dispatch({ type: PAYMENT_ACCEPT_FAILURE, payload: error.message });
            // toast.error(error?.response?.data?.message);
        }
    };
};