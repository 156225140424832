import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_URL;

export const ADMIN_RFQ_REQUEST = "ADMIN_RFQ_REQUEST";
export const ADMIN_RFQ_SUCCESS = "ADMIN_RFQ_SUCCESS";
export const ADMIN_RFQ_FAILURE = "ADMIN_RFQ_FAILURE";

export const GET_ADMIN_RFQ_REQUEST = "GET_ADMIN_RFQ_REQUEST";
export const GET_ADMIN_RFQ_SUCCESS = "GET_ADMIN_RFQ_SUCCESS";
export const GET_ADMIN_RFQ_FAILURE = "GET_ADMIN_RFQ_FAILURE";
