import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
// import Footer from './shared/Footer';

const App = ({ location }) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);

  const onRouteChanged = () => {
    const body = document.querySelector("body");
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/404", "/500"];
    const isFullPage = fullPageLayoutRoutes.includes(location.pathname);
    setIsFullPageLayout(isFullPage);
  };

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  let navbarComponent = !isFullPageLayout ? <Navbar /> : "";
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : "";
  // let footerComponent = !isFullPageLayout ? <Footer /> : '';

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div
        className={`container-fluid page-body-wrapper ${
          location.pathname === "/login"
            ? "full-page-wrapper"
            : location.pathname === "/404"
            ? "full-page-wrapper"
            : location.pathname === "/500"
            ? "full-page-wrapper"
            : ""
        }`}
      >
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
          {/* {footerComponent} */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);
