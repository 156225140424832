import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as bannerAction from "../../redux/banner/bannerAction";

const CreateWebBanner = () => {
  const [bannerImage, setBannerImage] = useState(null);
  const [productImage, setProductImage] = useState(null);
  const [tag, setTag] = useState("");
  const [text, setText] = useState("");
  const [btnAction, setBtnAction] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBannerImage = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    console.log(chosenFiles, "banner");
    setBannerImage(chosenFiles);
  };
  const handleProductImage = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    console.log(chosenFiles, "product");
    setProductImage(chosenFiles);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedFiles = [bannerImage, productImage].flat();
    try {
      dispatch(
        bannerAction.createBanner(
          {
            tag,
            text,
            btnAction,
          },
          selectedFiles,
          history
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleBannerGridView = () => {
    const numberOfColumns = Math.max(2, Math.ceil(bannerImage.length / 2));
    let gridFr = [];
    for (let i = 0; i < numberOfColumns; i++) {
      gridFr.push("1fr");
    }
    return gridFr.join(" ");
  };
  const handleProductGridView = () => {
    const numberOfColumns = Math.max(2, Math.ceil(productImage.length / 2));
    let gridFr = [];
    for (let i = 0; i < numberOfColumns; i++) {
      gridFr.push("1fr");
    }
    return gridFr.join(" ");
  };
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title"> Create Banner </h3>
      </div>
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Enter new banner details</h4>

              <form className="form-sample">
                <div className="row">
                  <div className="col-xl-3 col-lg-5 col-md-5 col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-12 d-flex flex-column">
                        <p>Add Banner Image</p>
                        <div className="product-img-upload">
                          <div
                            style={
                              bannerImage?.length > 0
                                ? {
                                  display: "grid",
                                  gap: "10px",
                                  gridTemplateColumns: handleBannerGridView(),
                                }
                                : { display: "block" }
                            }
                          >
                            {bannerImage?.map((item, i) => {
                              return (
                                <>
                                  <img
                                    key={i}
                                    className="w-100"
                                    style={{ aspectRatio: "4/3" }}
                                    src={URL.createObjectURL(item)}
                                    alt="profile"
                                    srcset=""
                                  />
                                </>
                              );
                            })}
                            {!bannerImage?.length > 0 ? (
                              <img
                                className="w-100 h-100"
                                src="http://100dayscss.com/codepen/upload.svg"
                                alt="profile"
                                srcset=""
                              />
                            ) : (
                              ""
                            )}
                            <button className="primary-btn upload-new-product-img w-100">
                              <input
                                type="file"
                                // multiple
                                value=""
                                accept="image/*"
                                onChange={(e) => handleBannerImage(e)}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-5 col-md-5 col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-12 d-flex flex-column">
                        <p>Add Product Image</p>
                        <div className="product-img-upload">
                          <div
                            style={
                              productImage?.length > 0
                                ? {
                                  display: "grid",
                                  gap: "10px",
                                  gridTemplateColumns:
                                    handleProductGridView(),
                                }
                                : { display: "block" }
                            }
                          >
                            {productImage?.map((item, i) => {
                              return (
                                <>
                                  <img
                                    key={i}
                                    className="w-100"
                                    style={{ aspectRatio: "4/3" }}
                                    src={URL.createObjectURL(item)}
                                    alt="profile"
                                    srcset=""
                                  />
                                </>
                              );
                            })}
                            {!productImage?.length > 0 ? (
                              <img
                                className="w-100 h-100"
                                src="http://100dayscss.com/codepen/upload.svg"
                                alt="profile"
                                srcset=""
                              />
                            ) : (
                              ""
                            )}
                            <button className="primary-btn upload-new-product-img w-100">
                              <input
                                type="file"
                                // multiple
                                value=""
                                accept="image/*"
                                onChange={(e) => handleProductImage(e)}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label for="exampleInputUsername1">Tag</label>
                          <input
                            onChange={(e) => {
                              setTag(e.target.value);
                            }}
                            placeholder="Enter tag"
                            type="text"
                            id="exampleInputUsername1"
                            className="form-control form-control-lg"
                          />
                        </div>
                        <div className="form-group">
                          <label for="exampleInputUsername1">Text</label>
                          <input
                            onChange={(e) => {
                              setText(e.target.value);
                            }}
                            placeholder="Enter Text"
                            type="text"
                            id="exampleInputUsername1"
                            className="form-control form-control-lg"
                          />
                        </div>
                        <div className="form-group">
                          <label for="exampleInputUsername1">
                            ProductId for Button Action
                          </label>
                          <input
                            onChange={(e) => {
                              setBtnAction(e.target.value);
                            }}
                            placeholder="Enter ProductId for Button Action"
                            type="text"
                            id="exampleInputUsername1"
                            className="form-control form-control-lg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary mr-2"
                >
                  Submit
                </button>
                <button className="btn btn-light">Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateWebBanner;
