import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

//  get industry
export const GET_INDUSTRY_REQUEST = "GET_INDUSTRY_REQUEST";
export const GET_INDUSTRY_SUCCESS = "GET_INDUSTRY_SUCCESS";
export const GET_INDUSTRY_FAILURE = "GET_INDUSTRY_FAILURE";

// get subcategory
export const GET_SUBCATEGORY_REQUEST = "GET_SUBCATEGORY_REQUEST";
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_FAILURE = "GET_SUBCATEGORY_FAILURE";

// create subcategory
export const CREATE_SUBCATEGORY_REQUEST = "CREATE_SUBCATEGORY_REQUEST";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAILURE = "CREATE_SUBCATEGORY_FAILURE";

// update subcategory
export const UPDATE_SUBCATEGORY_REQUEST = "UPDATE_SUBCATEGORY_REQUEST";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAILURE = "UPDATE_SUBCATEGORY_FAILURE";

// delete subcategory
export const DELETE_SUBCATEGORY_REQUEST = "DELETE_SUBCATEGORY_REQUEST";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";

// create child catgeogry
export const CREATE_CHILDCATEGORY_REQUEST = "CREATE_CHILDCATEGORY_REQUEST";
export const CREATE_CHILDCATEGORY_SUCCESS = "CREATE_CHILDCATEGORY_SUCCESS";
export const CREATE_CHILDCATEGORY_FAILURE = "CREATE_CHILDCATEGORY_FAILURE";

// update child catgeogry
export const UPDATE_CHILDCATEGORY_REQUEST = "UPDATE_CHILDCATEGORY_REQUEST";
export const UPDATE_CHILDCATEGORY_SUCCESS = "UPDATE_CHILDCATEGORY_SUCCESS";
export const UPDATE_CHILDCATEGORY_FAILURE = "UPDATE_CHILDCATEGORY_FAILURE";

// delete child catgeogry
export const DELETE_CHILDCATEGORY_REQUEST = "DELETE_CHILDCATEGORY_REQUEST";
export const DELETE_CHILDCATEGORY_SUCCESS = "DELETE_CHILDCATEGORY_SUCCESS";
export const DELETE_CHILDCATEGORY_FAILURE = "DELETE_CHILDCATEGORY_FAILURE";

// get child category
export const GET_CHILDCATEGORY_REQUEST = "GET_CHILDCATEGORY_REQUEST";
export const GET_CHILDCATEGORY_SUCCESS = "GET_CHILDCATEGORY_SUCCESS";
export const GET_CHILDCATEGORY_FAILURE = "GET_CHILDCATEGORY_FAILURE";

export const getIndustries = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INDUSTRY_REQUEST });
      const URL = `${BASE_URL}/industry/get/industry/list/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_INDUSTRY_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_INDUSTRY_FAILURE, payload: error.message });
    }
  };
};

// ----sub category
export const createSubCategories = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_SUBCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/sub/category/create/sub/category/v1`;
      const res = await axios.post(URL, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_SUBCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: CREATE_SUBCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

// ----update category
export const updateSubCategories = ({ id, updatedName }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/sub/category/update/sub/category/${id}/v1`;
      const res = await axios.put(
        URL,
        {
          subCategoryName: updatedName,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: UPDATE_SUBCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: UPDATE_SUBCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

// ----delete subCategory
export const deleteSubCategories = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SUBCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/sub/category/delete/sub/category/${id}/v1`;
      const res = await axios.delete(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_SUBCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: DELETE_SUBCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getSubCategories = (category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/sub/category/get/all/sub/categories/${category}/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });

      dispatch({ type: GET_SUBCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_SUBCATEGORY_FAILURE, payload: error.message });
    }
  };
};

//create child categories
export const createChildCategories = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_CHILDCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/child/category/create/child/category/v1`;
      const res = await axios.post(URL, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CREATE_CHILDCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: CREATE_CHILDCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

// ----update category
export const updateChildCategory = ({ id, updatedName }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CHILDCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/child/category/update/child/category/${id}/v1`;
      const res = await axios.put(
        URL,
        { childCategoryName: updatedName },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: UPDATE_CHILDCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: UPDATE_CHILDCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

// ----delete childCategory
export const deleteChildCategory = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CHILDCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/child/category/delete/child/category/${id}/v1`;
      const res = await axios.delete(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_CHILDCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: DELETE_CHILDCATEGORY_FAILURE, payload: error.message });
      toast(error?.response?.data?.message);
    }
  };
};

export const getChildCategories = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CHILDCATEGORY_REQUEST });
      const URL = `${BASE_URL}/product/child/category/get/all/child/categories/${id}/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_CHILDCATEGORY_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: GET_CHILDCATEGORY_FAILURE, payload: error.message });
    }
  };
};
