import * as userAction from "./userAction";

export const userFeatureKey = "userInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  users: [],
  userDetails: {},
  count:[]
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case userAction.GET_USER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userAction.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        users: payload.data,
        count: payload.count[0]?.total,
      };

    case userAction.GET_USER_LIST_FAILURE:
      return {
        ...state,
        errorMsg: payload,
      };
      case userAction.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case userAction.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: payload?.data,
      };

    case userAction.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
