
import { Route, Redirect } from "react-router-dom";

let PrivateRoute = ({ component: Component, ...rest }) => {
  // const token = JSON.parse(localStorage.getItem('@@auth0spajs@@::dzXEgcZyKELadKM0rCjxcdNwmgxMMwsP::default::openid profile email'))
  // console.log('token', token.body.id_token)
  // if (token.body.id_token) {
  //   localStorage.setItem('Authorization', `Bearer ${token?.body?.id_token}`)
  // }
  return (
    <Route
      {...rest}
      render={(props) => {
        return !localStorage.getItem('token') ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};
export default PrivateRoute;