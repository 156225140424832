import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

export const UPDATE_BANK_DETAILS_REQUEST = "UPDATE_BANK_DETAILS_REQUEST";
export const UPDATE_BANK_DETAILS_SUCCESS = "UPDATE_BANK_DETAILS_SUCCESS";
export const UPDATE_BANK_DETAILS_FAILURE = "UPDATE_BANK_DETAILS_FAILURE";

export const GET_BANK_DETAILS_REQUEST = "GET_BANK_DETAILS_REQUEST";
export const GET_BANK_DETAILS_SUCCESS = "GET_BANK_DETAILS_SUCCESS";
export const GET_BANK_DETAILS_FAILURE = "GET_BANK_DETAILS_FAILURE";

export const updateBankDetails = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: "IS_STATUS_CHANGE" });
            dispatch({ type: UPDATE_BANK_DETAILS_REQUEST });
            const url = `${BASE_URL}/bank/update/v1`;

            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: UPDATE_BANK_DETAILS_SUCCESS, payload: response.data });
            toast.success("Bank Details Updated Successfully");
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_BANK_DETAILS_FAILURE, payload: error.message });
            toast.error(error?.response?.data?.message);
        }
    };
};

export const getBankDetails = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_BANK_DETAILS_REQUEST });
            const url = `${BASE_URL}/bank/get/details/admin/v1`;

            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            });
            dispatch({ type: GET_BANK_DETAILS_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_BANK_DETAILS_FAILURE, payload: error.message });
            toast.error(error?.response?.data?.message);
        }
    };
};