import axios from "axios";
import { toast } from "react-toastify";

export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILURE = "LOGIN_ADMIN_FAILURE";

// cahnge password
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

const BASE_URL = process.env.REACT_APP_URL;

export const loginAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_ADMIN_REQUEST });
      const url = `${BASE_URL}/auth/login/admin/v1`;
      const response = await axios.post(url, data);
      dispatch({ type: LOGIN_ADMIN_SUCCESS, payload: response.data });
      history.push("/dashboard");
      toast.success("Login Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_ADMIN_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const changePassword = (data, clearData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST });
      const url = `${BASE_URL}/auth/change/password/admin/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
      clearData();
      toast.success("password change Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error(error);
      dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
