import * as inquiryAction from "./inquiryAction";

export const inquiryFeatureKey = "inquiryInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  inquiryList: [],
  inquiryDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // inquiry list
    case inquiryAction.GET_INQUIRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case inquiryAction.GET_INQUIRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiryList: payload?.data,
      };
    case inquiryAction.GET_INQUIRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get single inquiry

    case inquiryAction.GET_INQUIRY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case inquiryAction.GET_INQUIRY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        inquiryDetails: payload.data,
      };
    case inquiryAction.GET_INQUIRY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
