import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_URL;

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const CREATE_PRODUCT_REQUEST = "CREATE_PRODUCT_REQUEST";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILURE = "CREATE_PRODUCT_FAILURE";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILURE = "GET_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const PRODUCT_CATEGORY_LIST_REQUEST = "PRODUCT_CATEGORY_LIST_REQUEST";
export const PRODUCT_CATEGORY_LIST_SUCCESS = "PRODUCT_CATEGORY_LIST_SUCCESS";
export const PRODUCT_CATEGORY_LIST_FAILURE = "PRODUCT_CATEGORY_LIST_FAILURE";

export const CREATE_PRODUCT_CATEGORY_REQUEST =
  "CREATE_PRODUCT_CATEGORY_REQUEST";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_FAILURE =
  "CREATE_PRODUCT_CATEGORY__FAILURE";

export const UPDATE_PRODUCT_CATEGORY_REQUEST =
  "UPDATE_PRODUCT_CATEGORY_REQUEST";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAILURE =
  "UPDATE_PRODUCT_CATEGORY__FAILURE";

export const DELETE_PRODUCT_CATEGORY_REQUEST =
  "DELETE_PRODUCT_CATEGORY_REQUEST";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAILURE =
  "DELETE_PRODUCT_CATEGORY__FAILURE";

export const setCurrentPage = (page) => ({
  type: "SET_CURRENT_PAGE",
  payload: page,
});

export const productList = (page, category) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const url = `${BASE_URL}/product/list/v1?page=${page}&category=${category}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: PRODUCT_LIST_SUCCESS, payload: response.data });
      dispatch({ type: SET_CURRENT_PAGE, payload: page });
    } catch (error) {
      console.error(error);
      dispatch({ type: PRODUCT_LIST_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};

export const productCategoryList = (industry) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_CATEGORY_LIST_REQUEST });
      const url = `${BASE_URL}/product/category/mobile/get/all/categories/${industry}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({ type: PRODUCT_CATEGORY_LIST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: PRODUCT_CATEGORY_LIST_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};

export const createProduct = (data, chosenFiles, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PRODUCT_REQUEST });
      if (chosenFiles.length > 0) {
        const imgArr = [];
        const uploadPromises = chosenFiles.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/product/upload/product/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`/${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        data.Images = imgArr;
      }

      const url = `${BASE_URL}/product/create/product/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully created");
      dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: response.data });
      history.push("/products");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_PRODUCT_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

// create category
export const createCategory = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PRODUCT_CATEGORY_REQUEST });
      const url = `${BASE_URL}/product/category/create/category/v1`;
      const response = await axios.post(url, data, {
        headers: {
          //   Authorization:localStorage.getItem("token"),
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CREATE_PRODUCT_CATEGORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: CREATE_PRODUCT_CATEGORY_FAILURE,
        payload: error.message,
      });
      // toast(error?.response?.data?.message);
    }
  };
};

// update category
export const updateCategory = ({ id, updatedName }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PRODUCT_CATEGORY_REQUEST });
      const url = `${BASE_URL}/product/category/update/category/${id}/v1`;
      const response = await axios.put(
        url,
        { category: updatedName },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({
        type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_PRODUCT_CATEGORY_FAILURE,
        payload: error.message,
      });
      toast(error?.response?.data?.message);
    }
  };
};

//delete category
export const deleteCategory = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PRODUCT_CATEGORY_REQUEST });
      const url = `${BASE_URL}/product/category/delete/category/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: DELETE_PRODUCT_CATEGORY_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_PRODUCT_CATEGORY_FAILURE,
        payload: error.message,
      });
      // toast(error?.response?.data?.message);
    }
  };
};

export const updateProduct = (data, selectedFile, id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });

      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/product/upload/product/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`/${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        data.image = [...imgArr, ...data.image];
      }

      const url = `${BASE_URL}/product/update/product/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          //   Authorization:localStorage.getItem("token"),
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("product updated successfully");
      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: response.data });
      history.push("/products");
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_PRODUCT_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};

export const getOneProduct = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCT_REQUEST });
      const url = `${BASE_URL}/product/get/single/product/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          //   Authorization:localStorage.getItem("token"),
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_PRODUCT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PRODUCT_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};


export const deleteProduct = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
      const url = `${BASE_URL}/product/delete/product/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_PRODUCT_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};
