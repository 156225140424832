import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "./ChangePassword/ChangePassword";
import WebBanner from "./banner/WebBanner";
import MobileBanner from "./banner/MobileBanner";
import CreateWebBanner from "./banner/CreateWebBanner";
import CreateMobileBanner from "./banner/CreateMobileBanner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));

const Users = lazy(() => import("./user/Users"));
const UserDetail = lazy(() => import("./user/UserDetail"));

const Products = lazy(() => import("./product/Products"));
const CreateProduct = lazy(() => import("./product/CreateProduct"));
const ProductDetail = lazy(() => import("./product/ProductDetail"));
const UpdateProduct = lazy(() => import("./product/UpdateProduct"));

const Category = lazy(() => import("./category/Category"));

const Orders = lazy(() => import("./orders/Orders"));
const OrderDetail = lazy(() => import("./orders/OrderDetail"));

const Gallery = lazy(() => import("./gallery/Gallery"));

const Payments = lazy(() => import("./payments/Payments"));
const PaymentDetails = lazy(() => import("./payments/PaymentDetails"));

const Contact = lazy(() => import("./contact/Contact"));
const ContactDetail = lazy(() => import("./contact/ContactDetail"));

const Inquiry = lazy(() => import("./inquiry/Inquiry"));
const InquiryDetails = lazy(() => import("./inquiry/InquiryDetails"));

const Bank = lazy(() => import("./bank/Bank"));

const Payment = lazy(() => import("./payment/Payment"));
const PaymentDetail = lazy(() => import("./payment/PaymentDetails"));

const QRList = lazy(() => import("./qr/QRList"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Login = lazy(() => import("./user-pages/Login"));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          isAuthenticated={false}
        />

        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/user/:_id" component={UserDetail} />
        <PrivateRoute
          exact
          path="/change-password"
          component={ChangePassword}
        />

        <PrivateRoute path="/products" component={Products} />
        <PrivateRoute path="/create-product" component={CreateProduct} />
        <PrivateRoute path="/product/detail/:id" component={ProductDetail} />
        <PrivateRoute path="/product/:id/update" component={UpdateProduct} />

        <PrivateRoute path="/category" component={Category} />

        <PrivateRoute path="/banner-web" component={WebBanner} />
        <PrivateRoute path="/create-web-banner" component={CreateWebBanner} />

        <PrivateRoute path="/banner-mobile" component={MobileBanner} />
        <PrivateRoute
          path="/create-mobile-banner"
          component={CreateMobileBanner}
        />

        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute path="/order/:_id" component={OrderDetail} />

        <PrivateRoute path="/gallery" component={Gallery} />

        <PrivateRoute path="/payments" component={Payments} />
        <PrivateRoute path="/payment/details/:id" component={PaymentDetails} />

        <PrivateRoute path="/contacts" component={Contact} />
        <PrivateRoute path="/contact/:_id" component={ContactDetail} />

        <PrivateRoute exact path="/inquiry" component={Inquiry} />
        <PrivateRoute path="/inquiry/:_id" component={InquiryDetails} />

        <PrivateRoute path="/bank" component={Bank} />

        <PrivateRoute path="/payment/view/:id" component={PaymentDetail} />
        <PrivateRoute path="/payment" component={Payment} />

        <PrivateRoute path="/qrcode" component={QRList} />

        <Redirect path="/" to="/login" exact />

        <Route path="/login" component={Login} />

        <PrivateRoute path="/404" component={Error404} />
        <PrivateRoute path="/500" component={Error500} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
