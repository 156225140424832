import * as galleryAction from "./galleryAction";

export const galleryFeatureKey = "galleryInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  galleryImage: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // create gallery image
    case galleryAction.CREATE_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case galleryAction.CREATE_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        // galleryImage: payload.data,
      };
    case galleryAction.CREATE_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // gallery list
    case galleryAction.GET_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case galleryAction.GET_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        galleryImage: payload.data,
      };
    case galleryAction.GET_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case galleryAction.DELETE_GALLERY_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case galleryAction.DELETE_GALLERY_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        galleryImage: payload.data,
      };
    case galleryAction.DELETE_GALLERY_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
