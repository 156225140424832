import * as qrAction from "./qrAction";

export const qrFeatureKey = "qrInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  qrDetails: {},
  isQrUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case qrAction.CREATE_QR_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        isQrUpdated: false,
      };

    case qrAction.CREATE_QR_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isQrUpdated: true,
      };
    case qrAction.CREATE_QR_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case qrAction.UPDATE_QR_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        isQrUpdated: false,
      };

    case qrAction.UPDATE_QR_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isQrUpdated: true,
      };
    case qrAction.UPDATE_QR_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case qrAction.GET_QR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case qrAction.GET_QR_SUCCESS:
      return {
        ...state,
        loading: false,
        qrDetails: payload.data,
      };

    case qrAction.GET_QR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case qrAction.DELETE_QR_REQUEST:
      return {
        ...state,
        loading: true,
        isQrUpdated: false,
      };

    case qrAction.DELETE_QR_SUCCESS:
      return {
        ...state,
        loading: false,
        isQrUpdated: true,
      };
    case qrAction.DELETE_QR_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
