import * as bannerAction from "./bannerAction";

export const bannerFeatureKey = "bannerInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  banner: [],
  bannerMobile: [],
  bannerDetails: {},
  isBannerUpdateStatus: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // create banner action
    case bannerAction.CREATE_BANNER_WEB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.CREATE_BANNER_WEB_SUCCESS:
      return {
        ...state,
        loading: false,
        bannerDetails: payload.data,
      };

    case bannerAction.CREATE_BANNER_WEB_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //   create banner for mobile
    case bannerAction.CREATE_BANNER_MOBILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.CREATE_BANNER_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        bannerDetails: payload.data,
      };

    case bannerAction.CREATE_BANNER_MOBILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //   get banner
    case bannerAction.GET_BANNER_WEB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.GET_BANNER_WEB_SUCCESS:
      return {
        ...state,
        loading: false,
        banner: payload.data,
      };
    case bannerAction.GET_BANNER_WEB_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //   get banner mobile
    case bannerAction.GET_BANNER_MOBILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.GET_BANNER_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        bannerMobile: payload.data,
      };
    case bannerAction.GET_BANNER_MOBILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case bannerAction.DELETE_BANNER_WEB_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.DELETE_BANNER_WEB_SUCCESS:
      return {
        ...state,
        loading: false,
        isBannerUpdateStatus: true,
      };
    case bannerAction.DELETE_BANNER_WEB_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case bannerAction.DELETE_BANNER_MOBILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerAction.DELETE_BANNER_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isBannerUpdateStatus: true,
      };
    case bannerAction.DELETE_BANNER_MOBILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "IS_BANNER_UPDATE":
      return {
        ...state,
        isBannerUpdateStatus: false,
      };
    default:
      return state;
  }
};
