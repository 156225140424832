import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const getUserList = (page, searchQuery) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_LIST_REQUEST });
      const URL = `${BASE_URL}/user/admin/get/user/list/v1?page=${page}&searchQuery=${searchQuery}`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_LIST_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_USER_LIST_FAILURE, payload: error.message });
      console.log(error);
      // toast(error.message);
    }
  };
};

export const getUserDetails = (_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_DETAILS_REQUEST });
      const URL = `${BASE_URL}/user/admin/get/user/${_id}/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_USER_DETAILS_FAILURE, payload: error.message });
      console.log(error);
      // toast(error.message);
    }
  };
};
