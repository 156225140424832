import axios from "axios";
import { toast } from "react-toastify";
// const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const BASE_URL = process.env.REACT_APP_URL;

export const CREATE_BANNER_WEB_REQUEST = "CREATE_BANNER_WEB_REQUEST";
export const CREATE_BANNER_WEB_SUCCESS = "CREATE_BANNER_WEB_SUCCESS";
export const CREATE_BANNER_WEB_FAILURE = "CREATE_BANNER_WEB_FAILURE";

export const GET_BANNER_WEB_REQUEST = "GET_BANNER_WEB_REQUEST";
export const GET_BANNER_WEB_SUCCESS = "GET_BANNER_WEB_SUCCESS";
export const GET_BANNER_WEB_FAILURE = "GET_BANNER_WEB_FAILURE";

export const DELETE_BANNER_WEB_REQUEST = "DELETE_BANNER_WEB_REQUEST";
export const DELETE_BANNER_WEB_SUCCESS = "DELETE_BANNER_WEB_SUCCESS";
export const DELETE_BANNER_WEB_FAILURE = "DELETE_BANNER_WEB_FAILURE";

export const UPDATE_BANNER_WEB_REQUEST = "UPDATE_BANNER_WEB_REQUEST";
export const UPDATE_BANNER_WEB_SUCCESS = "UPDATE_BANNER_WEB_SUCCESS";
export const UPDATE_BANNER_WEB_FAILURE = "UPDATE_BANNER_WEB_FAILURE";

// MOBILE

export const CREATE_BANNER_MOBILE_REQUEST = "CREATE_BANNER_MOBILE_REQUEST";
export const CREATE_BANNER_MOBILE_SUCCESS = "CREATE_BANNER_MOBILE_SUCCESS";
export const CREATE_BANNER_MOBILE_FAILURE = "CREATE_BANNER_MOBILE_FAILURE";

export const GET_BANNER_MOBILE_REQUEST = "GET_BANNER_MOBILE_REQUEST";
export const GET_BANNER_MOBILE_SUCCESS = "GET_BANNER_MOBILE_SUCCESS";
export const GET_BANNER_MOBILE_FAILURE = "GET_BANNER_MOBILE_FAILURE";

export const DELETE_BANNER_MOBILE_REQUEST = "DELETE_BANNER_MOBILE_REQUEST";
export const DELETE_BANNER_MOBILE_SUCCESS = "DELETE_BANNER_MOBILE_SUCCESS";
export const DELETE_BANNER_MOBILE_FAILURE = "DELETE_BANNER_MOBILE_FAILURE";

export const UPDATE_BANNER_MOBILE_REQUEST = "UPDATE_BANNER_MOBILE_REQUEST";
export const UPDATE_BANNER_MOBILE_SUCCESS = "UPDATE_BANNER_MOBILE_SUCCESS";
export const UPDATE_BANNER_MOBILE_FAILURE = "UPDATE_BANNER_MOBILE_FAILURE";

export const createBanner = (data, chosenFiles, history) => {
  return async (dispatch) => {
    console.log(chosenFiles, "choosenFile");
    try {
      dispatch({ type: CREATE_BANNER_WEB_REQUEST });
      if (chosenFiles.length > 0) {
        const imgArr = [];
        const uploadPromises = chosenFiles.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/banner/upload/banner/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`/${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.image = imgArr[0];
        data.product = imgArr[1];
      }
      console.log(data);
      const url = `${BASE_URL}/banner/add/banner/info/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully created");
      dispatch({ type: CREATE_BANNER_WEB_SUCCESS, payload: response.data });
      history.push("/banner-web");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_BANNER_WEB_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

// setup for create banner for mobile

export const createBannerMobile = (data, chosenFiles, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_BANNER_MOBILE_REQUEST });
      if (chosenFiles.length > 0) {
        const imgArr = [];
        const uploadPromises = chosenFiles.map(async (file) => {
          try {
            const imgRes = await axios.post(
              `${BASE_URL}/banner/upload/banner/image`,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`/${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        // data.Images = imgArr;
        data.image = imgArr[0];
      }
      console.log(data);
      const url = `${BASE_URL}/banner/add/mobile/banner/info/v1`;

      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Successfully created");
      dispatch({ type: CREATE_BANNER_MOBILE_SUCCESS, payload: response.data });
      history.push("/banner-mobile");
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_BANNER_MOBILE_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message);
    }
  };
};

// for banners for web

export const bannerImageListWeb = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BANNER_WEB_REQUEST });
      const url = `${BASE_URL}/banner/get/web/banner/info/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_BANNER_WEB_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_BANNER_WEB_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};

export const bannerImageListMobile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BANNER_MOBILE_REQUEST });
      const url = `${BASE_URL}/banner/get/mobile/banner/info/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_BANNER_MOBILE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_BANNER_MOBILE_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};

export const deleteBannerWeb = (bannerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_BANNER_UPDATE" });
      dispatch({ type: DELETE_BANNER_WEB_REQUEST });
      const url = `${BASE_URL}/banner/delete/web/banner/info/${bannerId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_BANNER_WEB_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_BANNER_WEB_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};


export const deleteBannerMobile = (bannerId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_BANNER_UPDATE" });
      dispatch({ type: DELETE_BANNER_MOBILE_REQUEST });
      const url = `${BASE_URL}/banner/delete/mobile/banner/info/${bannerId}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_BANNER_MOBILE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_BANNER_MOBILE_FAILURE, payload: error.message });
      // toast(error?.response?.data?.message);
    }
  };
};
