import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CustomModal = ({
  qrDetails,
  forQR= false,
  btnClass,
  modalBodyContent,
  withButton,
  imgUploader = false,
  primaryBtnText = false,
  secondaryBtnText = false,
  handleSubmit,
  handleLogout,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState();

  const history = useHistory();

  const handleSelectImage = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    if (chosenFiles.length <= 10) {
      setSelectedFile(chosenFiles);
    } else {
      toast.info("cant upload more than 10 images", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 7000,
      });
    }
  };

  // const handleGridView = () => {
  //   const length = Math.ceil(selectedFile.length / 2);
  //   let gridFr = [];
  //   for (let i = 0; i < length; i++) {
  //     gridFr.push("1fr");
  //   }
  //   return gridFr.join(" ");
  // };

  const handleGridView = () => {
    const numberOfColumns = Math.max(2, Math.ceil(selectedFile.length / 2));
    let gridFr = [];
    for (let i = 0; i < numberOfColumns; i++) {
      gridFr.push("1fr");
    }
    return gridFr.join(" ");
  };

  const logoutAndNavigate = () => {
    localStorage.removeItem("token");
    if (handleLogout) {
      handleLogout();
    }
    history.push("/login");
  };

  return (
    <>
      {withButton ? (
        <button
          onClick={() => setModalShow(true)}
          className="btn btn-light bg-white btn-fw d-flex align-items-center"
        >
          {" "}
          <i className={`mdi ${btnClass} menu-icon mr-1`}></i>
          {qrDetails ? "Update QR" : forQR ? "Add New QR" : "Add New Image"}
        </button>
      ) : (
        <i
          type="button"
          onClick={() => setModalShow(true)}
          className={`mdi ${btnClass} menu-icon p-1`}
        ></i>
      )}

      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h4>{modalBodyContent}</h4>

          {imgUploader ? (
            <div className="row">
              <div className="col-12 d-flex">
                <div className="product-img-upload">
                  <div
                    style={
                      selectedFile?.length > 0
                        ? {
                            display: "grid",
                            gap: "10px",
                            gridTemplateColumns: handleGridView(),
                          }
                        : { display: "block" }
                    }
                  >
                    {selectedFile?.map((item) => {
                      return (
                        <>
                          <img
                            className="w-100"
                            style={{ aspectRatio: "4/3" }}
                            src={URL.createObjectURL(item)}
                            alt="profile"
                            srcset=""
                          />
                        </>
                      );
                    })}
                    {!selectedFile ? (
                      <img
                        className="w-100 h-100"
                        src="http://100dayscss.com/codepen/upload.svg"
                        alt="profile"
                        srcset=""
                      />
                    ) : (
                      ""
                    )}
                    <button className="primary-btn upload-new-product-img w-100">
                      <input
                        type="file"
                        multiple
                        value=""
                        accept="image/*"
                        onChange={(e) => handleSelectImage(e)}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-fw"
            onClick={() => setModalShow(false)}
          >
            {secondaryBtnText ? secondaryBtnText : "Not now"}
          </button>
          <button
            type="button"
            className="btn btn-primary btn-fw"
            onClick={() => {
              // logoutAndNavigate();
              handleSubmit(selectedFile);
              setModalShow(false);
            }}
          >
            {primaryBtnText ? primaryBtnText : "Confirm"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomModal;
