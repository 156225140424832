import * as industryAction from "./industryAction";

export const industryFeatureKey = "industryInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  industries: [],
  subCategories: [],
  childCategories: [],
  count: 0,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // industry list
    case industryAction.GET_INDUSTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.GET_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        industries: payload.data,
        count: payload.data.length,
      };
    case industryAction.GET_INDUSTRY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // ---get SubCategory
    case industryAction.GET_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategories: payload.data,
      };
    case industryAction.GET_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // ---update SubCategory
    case industryAction.UPDATE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.UPDATE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // ---delete SubCategory
    case industryAction.DELETE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.DELETE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // ---create Subcategory
    case industryAction.CREATE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.CREATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.CREATE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create child category
    case industryAction.CREATE_CHILDCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.CREATE_CHILDCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.CREATE_CHILDCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update child category
    case industryAction.UPDATE_CHILDCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.UPDATE_CHILDCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.UPDATE_CHILDCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // delete child category
    case industryAction.DELETE_CHILDCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.DELETE_CHILDCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case industryAction.DELETE_CHILDCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // ---get Child Category
    case industryAction.GET_CHILDCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case industryAction.GET_CHILDCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        childCategories: payload.data,
      };
    case industryAction.GET_CHILDCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
