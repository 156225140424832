import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ isPathActive, iconClass, title, url }) => {
  return (
    // <li className={isPathActive(url) ? "nav-item active" : "nav-item"}>
    //   <Link className="nav-link" to={url}>
    //     <i className={`mdi ${iconClass} menu-icon`}></i>
    //     <span className={` menu-title`}>{title}</span>
    //   </Link>
    // </li>

    <li className={isPathActive(url) ? "nav-item active" : "nav-item"}>
      <Link className="nav-link" to={url}>
        <span className="menu-title">{title}</span>
        <i className={` mdi ${iconClass} menu-icon`}></i>

      </Link>
    </li>
  );
};

export default NavLink;
