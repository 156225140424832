import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import NavLink from "../ui-elements/NavLink";

const sidebarData = [
  { iconClass: "mdi-home", title: "Dashboard", url: "/dashboard" },
  { iconClass: "mdi-account-multiple", title: "Users", url: "/users" },
  {
    iconClass: "mdi-file-tree",
    title: "Orders",
    url: "/orders",
  },
  {
    iconClass: "mdi-bank",
    title: "Payment",
    url: "/payment",
  },
  {
    iconClass: "mdi-comment-question-outline",
    title: "Inquiry",
    url: "/inquiry",
  },
  {
    iconClass: "mdi-package-variant-closed",
    title: "Products",
    url: "/products",
  },
  {
    iconClass: "mdi-grid-large",
    title: "Category",
    url: "/category",
  },
  {
    iconClass: "mdi-contacts",
    title: "Contact",
    url: "/contacts",
  },
  { iconClass: "mdi-laptop", title: "Web Banner", url: "/banner-web" },
  { iconClass: "mdi-cellphone", title: "Mobile Banner", url: "/banner-mobile" },
  {
    iconClass: "mdi-image-multiple",
    title: "Gallery",
    url: "/gallery",
  },
  // {
  //   iconClass: "mdi-credit-card-outline",
  //   title: "Payments",
  //   url: "/payments",
  // },
  
 
  {
    iconClass: "mdi-lock-reset",
    title: "Change Password",
    url: "/change-password",
  },
 
  {
    iconClass: "mdi-bank",
    title: "Bank",
    url: "/bank",
  },
  {
    iconClass: "mdi-bank",
    title: "QR Code",
    url: "/qrcode",
  },
];

const Sidebar = ({ location }) => {
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/advanced-ui", state: "advancedUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
    ];
    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({
          ...prevState,
          [obj.state]: true,
        }));
      }
    });
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data) => {
          return (
            <NavLink
              isPathActive={isPathActive}
              iconclassName={data.iconClass}
              title={data.title}
              url={data.url}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);
