import * as contactAction from "./contactAction";

export const contactFeatureKey = "contactInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  contactList: [],
  contactUsDetails: {},
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // contact list
    case contactAction.GET_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case contactAction.GET_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contactList: payload.data,
      };
    case contactAction.GET_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // create contact
    case contactAction.CREATE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case contactAction.CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case contactAction.CREATE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // get single contact
    case contactAction.GET_SINGLE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case contactAction.GET_SINGLE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contactUsDetails: payload.data,
      };
    case contactAction.GET_SINGLE_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
