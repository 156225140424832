import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_URL;

export const GET_ORDER_LIST_REQUEST = "GET_ORDER_LIST_REQUEST";
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS";
export const GET_ORDER_LIST_FAILURE = "GET_ORDER_LIST_FAILURE";

export const GET_ORDER_DETAILS_REQUEST = "GET_ORDER_DETAILS_REQUEST";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";

export const CREATE_QUOTE_REQUEST = "CREATE_QUOTE_REQUEST";
export const CREATE_QUOTE_SUCCESS = "CREATE_QUOTE_SUCCESS";
export const CREATE_QUOTE_FAILURE = "CREATE_QUOTE_FAILURE";

export const CHANGE_ORDER_STATUS_REQUEST = "CHANGE_ORDER_STATUS_REQUEST";
export const CHANGE_ORDER_STATUS_SUCCESS = "CHANGE_ORDER_STATUS_SUCCESS";
export const CHANGE_ORDER_STATUS_FAILURE = "CHANGE_ORDER_STATUS_FAILURE";

export const CHANGE_ORDER_DETAILS_STATUS_REQUEST =
  "CHANGE_ORDER_DETAILS_STATUS_REQUEST";
export const CHANGE_ORDER_DETAILS_STATUS_SUCCESS =
  "CHANGE_ORDER_DETAILS_STATUS_SUCCESS";
export const CHANGE_ORDER_DETAILS_STATUS_FAILURE =
  "CHANGE_ORDER_DETAILS_STATUS_FAILURE";

export const ACCEPT_PRICE_PRODUCT_REQUEST = "ACCEPT_PRICE_PRODUCT_REQUEST";
export const ACCEPT_PRICE_PRODUCT_SUCCESS = "ACCEPT_PRICE_PRODUCT_SUCCESS";
export const ACCEPT_PRICE_PRODUCT_FAILURE = "ACCEPT_PRICE_PRODUCT_FAILURE";

export const REJECT_PRICE_PRODUCT_REQUEST = "REJECT_PRICE_PRODUCT_REQUEST";
export const REJECT_PRICE_PRODUCT_SUCCESS = "REJECT_PRICE_PRODUCT_SUCCESS";
export const REJECT_PRICE_PRODUCT_FAILURE = "REJECT_PRICE_PRODUCT_FAILURE";

export const ADD_TAX_REQUEST = "ADD_TAX_REQUEST";
export const ADD_TAX_SUCCESS = "ADD_TAX_SUCCESS";
export const ADD_TAX_FAILURE = "ADD_TAX_FAILURE";

export const CHANGE_DELIVERY_DATE_REQUEST = "CHANGE_DELIVERY_DATE_REQUEST";
export const CHANGE_DELIVERY_DATE_SUCCESS = "CHANGE_DELIVERY_DATE_SUCCESS";
export const CHANGE_DELIVERY_DATE_FAILURE = "CHANGE_DELIVERY_DATE_FAILURE";

export const getOrderList = (skip, search) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDER_LIST_REQUEST });
      const URL = `${BASE_URL}/order/list/admin/v1?skip=${skip}&search=${search}`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_ORDER_LIST_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const getOrderDetails = (orderId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ORDER_DETAILS_REQUEST });
      const URL = `${BASE_URL}/order/details/admin/${orderId}/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_ORDER_DETAILS_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_ORDER_DETAILS_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const adminCreateQoute = (
  id,
  price,
  description,
  handleClose,
  setDescription,
  setPrice
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: CREATE_QUOTE_REQUEST });
      const url = `${BASE_URL}/order/rfq/quotation/${id}/v1`;
      const res = await axios.put(
        url,
        { description, price },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: CREATE_QUOTE_SUCCESS, payload: res?.data });
      handleClose();
      setDescription("");
      setPrice(null);
    } catch (error) {
      dispatch({ type: CREATE_QUOTE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const changeOrderStatus = (id, status, closeModal, setData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: CHANGE_ORDER_STATUS_REQUEST });
      const url = `${BASE_URL}/order/change/status/${id}/admin/v1`;
      const res = await axios.put(
        url,
        { changeStatus: status },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: CHANGE_ORDER_STATUS_SUCCESS, payload: res?.data });
      closeModal();
      setData({
        id: "",
        status: "",
      });
    } catch (error) {
      dispatch({ type: CHANGE_ORDER_STATUS_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const changeOrderDetailsStatus = (id, status, closeModal, setData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: CHANGE_ORDER_DETAILS_STATUS_REQUEST });
      const url = `${BASE_URL}/order/details/change/status/${id}/admin/v1`;
      const res = await axios.put(
        url,
        { changeStatus: status },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: CHANGE_ORDER_DETAILS_STATUS_SUCCESS,
        payload: res?.data,
      });
      closeModal();
      setData({
        id: "",
        status: "",
      });
    } catch (error) {
      dispatch({
        type: CHANGE_ORDER_DETAILS_STATUS_FAILURE,
        payload: error.message,
      });
      console.log(error);
    }
  };
};

export const acceptPriceProduct = (id, closeModal, setId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: ACCEPT_PRICE_PRODUCT_REQUEST });
      const url = `${BASE_URL}/order/accept/admin/${id}/v1`;
      const res = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: ACCEPT_PRICE_PRODUCT_SUCCESS, payload: res?.data });
      closeModal(false);
      setId("");
    } catch (error) {
      dispatch({ type: ACCEPT_PRICE_PRODUCT_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const rejectPriceProduct = (
  id,
  closeModal,
  setId,
  reason,
  setReason
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: REJECT_PRICE_PRODUCT_REQUEST });
      const url = `${BASE_URL}/order/reject/admin/${id}/v1`;
      const res = await axios.put(
        url,
        { reason },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: REJECT_PRICE_PRODUCT_SUCCESS, payload: res?.data });
      closeModal(false);
      setId("");
      setReason("");
    } catch (error) {
      dispatch({ type: REJECT_PRICE_PRODUCT_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const addTax = (id, closeModal, data, setTaxData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: ADD_TAX_REQUEST });
      const url = `${BASE_URL}/order/add/tax/${id}/v1`;
      const res = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: ADD_TAX_SUCCESS, payload: res?.data });
      closeModal(false);
      setTaxData([{ taxType: "", percentage: null }]);
    } catch (error) {
      dispatch({ type: ADD_TAX_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const chnageDeliveryDate = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_ORDER_STATUS_CHANGE" });
      dispatch({ type: CHANGE_DELIVERY_DATE_REQUEST });
      const url = `${BASE_URL}/order/change/delivery/date/${id}/v1`;
      const res = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: CHANGE_DELIVERY_DATE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: CHANGE_DELIVERY_DATE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};
