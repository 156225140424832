import axios from "axios";
import { toast } from "react-toastify";

// const TOKEN =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTJmNWE4N2E2M2ZiNTAyNWU1NTFjZGYiLCJpYXQiOjE2OTc2MDIyNzMsImV4cCI6MTcyOTEzODI3M30.WsnnzkxOuAQWln3BlN-M5p99qzgSWv2sMQBx5Js8ExI";

const BASE_URL = process.env.REACT_APP_URL;

export const GET_GALLERY_IMAGE_REQUEST = "GET_GALLERY_IMAGE_REQUEST";
export const GET_GALLERY_IMAGE_SUCCESS = "GET_GALLERY_IMAGE_SUCCESS";
export const GET_GALLERY_IMAGE_FAILURE = "GET_GALLERY_IMAGE_FAILURE";

export const DELETE_GALLERY_IMAGE_REQUEST = "DELETE_GALLERY_IMAGE_REQUEST";
export const DELETE_GALLERY_IMAGE_SUCCESS = "DELETE_GALLERY_IMAGE_SUCCESS";
export const DELETE_GALLERY_IMAGE_FAILURE = "DELETE_GALLERY_IMAGE_FAILURE";

export const CREATE_GALLERY_IMAGE_REQUEST = "CREATE_GALLERY_IMAGE_REQUEST";
export const CREATE_GALLERY_IMAGE_SUCCESS = "CREATE_GALLERY_IMAGE_SUCCESS";
export const CREATE_GALLERY_IMAGE_FAILURE = "CREATE_GALLERY_IMAGE_FAILURE";

export const createGalleryImage = (chosenFiles) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_GALLERY_IMAGE_REQUEST });

      const uploadPromises = chosenFiles.map(async (file) => {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/gallery/upload/gallery/image`,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          const url = imgRes.data.data.sUrl;

          await axios.put(url, file, {
            headers: { "Content-Type": file.type },
          });

          return imgRes.data.data.sPath;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });

      const uploadedImages = await Promise.all(uploadPromises);

      const URL = `${BASE_URL}/gallery/add/images/v1`;
      const res = await axios.post(
        URL,
        { images: uploadedImages },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: CREATE_GALLERY_IMAGE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: CREATE_GALLERY_IMAGE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const getGalleryImage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_GALLERY_IMAGE_REQUEST });
      const URL = `${BASE_URL}/gallery/get/images/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        }
      });
      dispatch({ type: GET_GALLERY_IMAGE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_GALLERY_IMAGE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const deleteGalleryImage = (imageId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_GALLERY_IMAGE_REQUEST });
      const URL = `${BASE_URL}/gallery/delete/image/${imageId}/v1`;
      const res = await axios.put(URL, null, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: DELETE_GALLERY_IMAGE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: DELETE_GALLERY_IMAGE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};
