import axios from "axios";
import { toast } from "react-toastify";
const BASE_URL = process.env.REACT_APP_URL;

export const GET_QR_REQUEST = "GET_QR_REQUEST";
export const GET_QR_SUCCESS = "GET_QR_SUCCESS";
export const GET_QR_FAILURE = "GET_QR_FAILURE";

export const DELETE_QR_REQUEST = "DELETE_QR_REQUEST";
export const DELETE_QR_SUCCESS = "DELETE_QR_SUCCESS";
export const DELETE_QR_FAILURE = "DELETE_QR_FAILURE";

export const CREATE_QR_IMAGE_REQUEST = "CREATE_QR_IMAGE_REQUEST";
export const CREATE_QR_IMAGE_SUCCESS = "CREATE_QR_IMAGE_SUCCESS";
export const CREATE_QR_IMAGE_FAILURE = "CREATE_QR_IMAGE_FAILURE";

export const UPDATE_QR_IMAGE_REQUEST = "UPDATE_QR_IMAGE_REQUEST";
export const UPDATE_QR_IMAGE_SUCCESS = "UPDATE_QR_IMAGE_SUCCESS";
export const UPDATE_QR_IMAGE_FAILURE = "UPDATE_QR_IMAGE_FAILURE";

export const createQRImage = (chosenFiles) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_QR_IMAGE_REQUEST });

      const uploadPromises = chosenFiles.map(async (file) => {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/qr/upload/qr/image`,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          const url = imgRes.data.data.sUrl;

          await axios.put(url, file, {
            headers: { "Content-Type": file.type },
          });

          return imgRes.data.data.sPath;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });

      const uploadedImages = await Promise.all(uploadPromises);
      const firstImage = uploadedImages[0];

      const URL = `${BASE_URL}/qr/create/v1`;
      const res = await axios.post(
        URL,
        { qrImage: firstImage },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: CREATE_QR_IMAGE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: CREATE_QR_IMAGE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const updateQRImage = (chosenFiles) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_QR_IMAGE_REQUEST });

      const uploadPromises = chosenFiles.map(async (file) => {
        try {
          const imgRes = await axios.post(
            `${BASE_URL}/qr/upload/qr/image`,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          const url = imgRes.data.data.sUrl;

          await axios.put(url, file, {
            headers: { "Content-Type": file.type },
          });

          return imgRes.data.data.sPath;
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      });

      const uploadedImages = await Promise.all(uploadPromises);
      const firstImage = uploadedImages[0];

      const URL = `${BASE_URL}/qr/update/v1`;
      const res = await axios.put(
        URL,
        { qrImage: firstImage },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      dispatch({ type: UPDATE_QR_IMAGE_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: UPDATE_QR_IMAGE_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};

export const getQr = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QR_REQUEST });
      const URL = `${BASE_URL}/qr/get/v1`;
      const res = await axios.get(URL, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_QR_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: GET_QR_FAILURE, payload: error.message });
      console.log(error);
      // toast(error.message);
    }
  };
};

export const deleteQRImage = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_QR_REQUEST });
      const URL = `${BASE_URL}/qr/delete/v1`;
      const res = await axios.delete(
        URL,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: DELETE_QR_SUCCESS, payload: res?.data });
    } catch (error) {
      dispatch({ type: DELETE_QR_FAILURE, payload: error.message });
      console.log(error);
    }
  };
};
