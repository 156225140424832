import * as adminLoginAction from "./adminLoginAction";

export const adminFeatureKey = "adminInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  loggedUser: {},
  counts: {},
  adminDetails: {},
  devices: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // login user
    case adminLoginAction.LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminLoginAction.LOGIN_ADMIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        loggedUser: payload.data,
      };

    case adminLoginAction.LOGIN_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case adminLoginAction.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case adminLoginAction.CHANGE_PASSWORD_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        loading: false,
        // loggedUser: payload.data,
      };

    case adminLoginAction.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
