import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import * as bannerAction from "../../redux/banner/bannerAction";
import ViewMoreBtn from "../ui-elements/ViewMoreBtn";

const BASE_IMAGE_URL = process.env.REACT_APP_BASE_PATH;
const WebBanner = () => {
  const tableHeader = [
    "Banner Image",
    "Product Image",
    "Created At",
    "Details",
    "Action",
  ];
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [selectedBannerId, setSelectedBannerId] = React.useState(null);
  const banner = useSelector((state) => state.bannerInfo.banner);
  const isBannerUpdateStatus = useSelector(
    (state) => state.bannerInfo.isBannerUpdateStatus
  );
  console.log(isBannerUpdateStatus,"status")

  const prevProps = useRef({ isBannerUpdateStatus }).current;
  console.log(prevProps, "prev");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bannerAction.bannerImageListWeb());
  }, []);

  useEffect(() => {
    if (prevProps.isBannerUpdateStatus !== isBannerUpdateStatus) {
      if (isBannerUpdateStatus) {
        dispatch(bannerAction.bannerImageListWeb());
      }
    }
    return () => {
      prevProps.isBannerUpdateStatus = isBannerUpdateStatus;
    };
  }, [isBannerUpdateStatus]);

  const handleDelete = (e, bannerId) => {
    e.preventDefault();
    dispatch(bannerAction.deleteBannerWeb(bannerId));
    setDeleteModalShow(false);
  };

  // const history = useHistory();

  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     history.push("/login");
  //   }
  // }, [history]);

  return (
    <>
      <div>
        <div className="page-header">
          <h3 className="page-title"> Web Banner </h3>
          <Link
            to={"/create-web-banner"}
            className="btn btn-light bg-white btn-fw d-flex align-items-center"
          >
            {" "}
            <i className="mdi mdi-plus menu-icon mr-1"></i> Create Banner{" "}
          </Link>
        </div>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Banner List</h4>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {tableHeader.map((item, i) => {
                          return <th key={i}>{item}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {banner?.map((p) => (
                        <>
                          <tr key={p._id}>
                            <td>
                              {" "}
                              <img
                                src={`${BASE_IMAGE_URL}${p.image}`}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />{" "}
                            </td>
                            <td>
                              {" "}
                              <img
                                src={`${BASE_IMAGE_URL}${p.product}`}
                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                              />{" "}
                            </td>

                            <td> {p.createdAt ? p.createdAt : "-"} </td>
                            <td>
                              {" "}
                              <ViewMoreBtn
                                redirectUrl={`/product/detail/${p._id}`}
                              />
                            </td>
                            <td>
                              <Link
                                to={`/product/${p._id}/update`}
                                className="mr-4"
                              >
                                <i className="mdi mdi-file-edit-outline display-5 menu-icon p-1"></i>
                              </Link>
                              <i
                                type="button"
                                onClick={() => {
                                  setSelectedBannerId(p._id); // Set selected bannerId
                                  setDeleteModalShow(true);
                                }}
                                className="mdi mdi-delete-outline display-5 menu-icon p-1"
                              ></i>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={deleteModalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h4>Are you sure want to Delete this product!</h4>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light btn-fw"
            onClick={() => {
              setSelectedBannerId(null); // Clear selected bannerId
              setDeleteModalShow(false);
            }}
          >
            Not now
          </button>
          <button
            type="button"
            className="btn btn-primary btn-fw"
            onClick={(e) => handleDelete(e, selectedBannerId)}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default WebBanner;
