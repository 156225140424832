import * as orderAction from "./orderAction";

export const orderFeatureKey = "orderInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  orderList: [],
  orderDetails: {},
  deletedOrderDetails:{},
  count: null,
  isOrderStatusChange: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // contact list
    case orderAction.GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderList: payload.data,
        count: payload.count,
      };

    case orderAction.GET_ORDER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case orderAction.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetails: payload?.data,
        deletedOrderDetails: payload?.data1,
      };

    case orderAction.GET_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case orderAction.CREATE_QUOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.CREATE_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case orderAction.CHANGE_ORDER_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.CHANGE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.CHANGE_ORDER_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case orderAction.CHANGE_ORDER_DETAILS_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.CHANGE_ORDER_DETAILS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.CHANGE_ORDER_DETAILS_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // accept price order
    case orderAction.ACCEPT_PRICE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.ACCEPT_PRICE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.ACCEPT_PRICE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // reject price order
    case orderAction.REJECT_PRICE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.REJECT_PRICE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.REJECT_PRICE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // add order tax
    case orderAction.ADD_TAX_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.ADD_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.ADD_TAX_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // change delivery status

    case orderAction.CHANGE_DELIVERY_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case orderAction.CHANGE_DELIVERY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isOrderStatusChange: true,
      };

    case orderAction.CHANGE_DELIVERY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case "IS_ORDER_STATUS_CHANGE":
      return {
        ...state,
        isOrderStatusChange: false,
      };

    default:
      return state;
  }
};
