import * as rfqActions from "./rfqAction";

export const rfqFeatureKey = "rfqInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  myRfqs: "",
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //send rfq
    case rfqActions.ADMIN_RFQ_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case rfqActions.ADMIN_RFQ_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case rfqActions.ADMIN_RFQ_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    //get rfqs
    case rfqActions.GET_ADMIN_RFQ_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case rfqActions.GET_ADMIN_RFQ_SUCCESS:
      return {
        ...state,
        loading: false,
        myRfqs: action.payload,
      };
    case rfqActions.GET_ADMIN_RFQ_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
