import * as paymentActions from "./payment.actions";

export const paymentDetailsFeatureKey = "paymentDetailsInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  count: null,
  isStatusChange: false,
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case paymentActions.PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case paymentActions.PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
        count: payload.count,
      };

    case paymentActions.PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case paymentActions.PAYMENT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case paymentActions.PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case paymentActions.PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case paymentActions.PAYMENT_ACCEPT_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusChange: false,
      };

    case paymentActions.PAYMENT_ACCEPT_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusChange: true,
      };

    case paymentActions.PAYMENT_ACCEPT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isStatusChange: true,
      };

    case paymentActions.PAYMENT_REJECT_REQUEST:
      return {
        ...state,
        loading: true,
        isStatusChange: false,
      };

    case paymentActions.PAYMENT_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        isStatusChange: true,
      };

    case paymentActions.PAYMENT_REJECT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        isStatusChange: true,
      };

    default:
      return state;
  }
};
